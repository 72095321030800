import React from 'react';
import { Navbar , Container, Nav, NavDropdown, Image } from 'react-bootstrap';
import brandLogo from "../assets/images/brandLogo.png";
import "../../styles/common/Header.scss";
import { BiSearch } from 'react-icons/bi';

const Header = () => {
    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" id="navbar" className="w-100 justify-content-between">
        <Container>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="d-flex justify-content-between w-100 align-items-center" >
              <Nav.Link href="#search"><BiSearch/></Nav.Link>
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <NavDropdown title="Fees" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#fees1">Fees 1</NavDropdown.Item>
                <NavDropdown.Item href="#fees2">
                    Fees 2
                </NavDropdown.Item>
                            
                </NavDropdown>
                <Navbar.Brand href="#home">
                    <Image src={brandLogo} className="img-fluid brand-logo" alt="" />
                </Navbar.Brand>
                <Nav.Link href="#support">Support</Nav.Link>
                <Nav.Link href="#contact">Contact</Nav.Link>
                <Nav.Link href="#location">Location</Nav.Link>
            </Nav>
            {/* <Nav>
              <Nav.Link href="#deets">More deets</Nav.Link>
              <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
};

export default Header;