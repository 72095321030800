import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "../../styles/common/Footer.scss";
import { GrMail } from 'react-icons/gr';
import { ImLocation2 } from 'react-icons/im';
import { FiPhoneCall } from 'react-icons/fi';
import { GoDeviceMobile } from 'react-icons/go';
const Footer = () => {
    return (
        <>
        <div id="footer">
            <Container>
                <Row xs={1} md={3}>
                    <Col className="py-5">
                        <h1 className="footer-title">
                            Emergency Travel Pass (ETP) 
                        </h1>
                        <hr className="hr" />
                        <p className="footer-description">
                        This is the ETP portal of the Department of Immigration and Passport in Bangladesh. This will help you to submit your ETP application online , from the comfort of your home. Apply online , pay the fees and get your ETP!
                        </p>
                    </Col>
                    <Col className="py-5">
                        <h1 className="footer-title">
                            Contact
                        </h1>
                        <hr className="hr" />
                        <div className="contact-information footer-description">
                            <div><span className="me-2"><GrMail/></span>info@evisa .com.bd</div>
                            <div><span className="me-2"><ImLocation2/></span>House# 1047, Road# 45, Mirpur Dohs, Dhaka-1216, Bangladesh.</div>
                        </div>
                    </Col>
                    <Col className="py-5">
                        <h1 className="footer-title">
                            Call Us
                        </h1>
                        <hr className="hr" />
                        <div className="contact-information footer-description">
                            <div><span className="me-2"><FiPhoneCall/></span>+88 02 58070308</div>
                            <div><span className="me-2"><GoDeviceMobile/></span>+880 1780 003 338</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
            <Container fluid className='all-right-reserved'>
                <Container>© Techsist Ltd. All rights reserved.</Container>
            </Container>
        </>
    );
};

export default Footer;