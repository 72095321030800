import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "../styles/WelcomeToBangladesh.scss";
import welcomeImage from "../components/assets/images/welcomeImage.jpeg";
import poweredByImage from "../components/assets/images/poweredByImage.png";
import LearnMoreButton from "./LearnMoreButton";
const WelcomeToBangladesh = () => {
  return (
    <Container id="welcome-bangladesh" className="py-5">
      <Row>
        <Col md={6}>
          <h1 className="welcome-to-bangladesh-title">
            Welcome to Bangladesh <span>Emergency Travel Pass (ETP)</span>
          </h1>
          <p className="description">
            Emergency Travel Pass (ETP) is for travelers or passport holders to
            apply for emergency travel authorization in case of their passport
            gets lost or stolen.
          </p>
          <LearnMoreButton />
          <div className="powered-by text-center">
            <h6>Powered by</h6>
            <Image src={poweredByImage} alt="logo" className="img-fluid" />
          </div>
        </Col>
        <Col md={6}>
          <Image src={welcomeImage} alt="welcome-image" className="img-fluid" />
        </Col>
      </Row>
    </Container>
  );
};

export default WelcomeToBangladesh;
