import React from 'react';  

const LearnMoreButton = () => {
    return (
        <>
            <button className="learn-more my-3">Learn More</button>
        </>
    );
};

export default LearnMoreButton;