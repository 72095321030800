import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "../styles/PhotoCaptureInstruction.scss";
import photoCapture from "../components/assets/images/photoInstruction.png";
import { SiSquare } from "react-icons/si";
import LearnMoreButton from "./LearnMoreButton";
const PhotoCaptureInstruction = () => {
  return (
    <Container id="photo-capture" className="py-5">
      <Row>
        <Col md={6} className="order-2 order-sm-2 order-md-0 order-lg-0">
          <Image src={photoCapture} alt="" className="img-fluid" />
        </Col>
        <Col md={6}>
          <h1 className="title text-center">Photo Capture Instruction</h1>
          <div className="instruction py-4">
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">No older than 6 months</span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">1-1.5 inches in width</span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">
                Head and shoulders visible with your face taking up to 70-80% in
                the photo
              </span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">Focused and clear</span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">No mar or creases</span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">Directly looking at the camera</span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">Natural skin tone</span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">Suitable brightness and contrast</span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">
                Printed on a high-quality paper or high resolution
              </span>
            </div>
            <div className="instruction-title text-start">
              <SiSquare />
              <span className="mx-3">Colour neutral</span>
            </div>
          </div>
          <LearnMoreButton />
        </Col>
      </Row>
    </Container>
  );
};

export default PhotoCaptureInstruction;
