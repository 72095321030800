import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles/EtpProcedureVideo.scss";
import LearnMoreButton from "./LearnMoreButton";
import ReactPlayer from "react-player";
const EtpProcedureVideo = () => {
  return (
    <Container className="py-5">
      <Row>
        <Col md={6} className="pe-5">
          <h1 className="title text-center">ETP Procedure Video</h1>
          <p className="description">
            You can apply for an emergency travel document (sometimes called an
            'emergency passport') if you're abroad, need to travel, and cannot
            get a passport in time.
            <h6 className="py-3">
              You can apply for an emergency travel document if all the
              following apply:
            </h6>
            <ul>
              <li>You're a Bangladesh national</li>
              <li>You're outside the country of your birth</li>
              <li>
                Your passport has been lost, stolen, damaged, is full, has
                recently expired, or is with Passport Office or a foreign
                embassy
              </li>
              <li>
                You do not have time to renew or replace your passport from
                abroad before you travel
              </li>
              <li>
                You can provide proof of your travel plans, for example, booking
                confirmations (or detailed written travel plans if you cannot
                book ahead)
              </li>
            </ul>
          </p>
          <LearnMoreButton />
        </Col>
        <Col md={6}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=uniP4VL3m6w"
            width="640"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EtpProcedureVideo;
