import './App.scss';
import Footer from './components/common/Footer';
import Header from './components/common/Header';
import EtpProcedureVideo from './components/EtpProcedureVideo';
import PhotoCaptureInstruction from './components/PhotoCaptureInstruction';
import WelcomeToBangladesh from './components/WelcomeToBangladesh';

function App() {
  return (
    <div className="App">
      <Header />
      <WelcomeToBangladesh />
      <PhotoCaptureInstruction />
      <EtpProcedureVideo />
      <Footer />
    </div>
  );
}

export default App;
